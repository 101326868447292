import React from "react"
import classes from "./Form.module.css"

const Form = () => {
  return (
    <form
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      className={classes.formRoot}
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <div className={classes.inputsContainer}>
        <div className={classes.nameEmailContainer}>
          <label htmlFor="name" className={classes.label}>
            Name
          </label>
          <input
            type="text"
            name="name"
            placeholder="Name"
            id="name"
            className={[classes.input, classes.nameEmail].join(" ")}
            required
          />
          <label htmlFor="email" className={classes.label}>
            Email
          </label>
          <input
            type="text"
            name="email"
            placeholder="Email"
            id="email"
            className={[classes.input, classes.nameEmail].join(" ")}
            required
          />
        </div>
        <div className={classes.messageContainer}>
          <label htmlFor="message" className={classes.label}>
            Message
          </label>
          <textarea
            name="message"
            placeholder="Message"
            id="message"
            rows="3"
            className={[classes.input, classes.message].join(" ")}
            required
          />
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <input type="submit" value="Send" className={classes.sendButton} />
      </div>
    </form>
  )
}

export default Form
