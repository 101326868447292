import React from "react"
import classes from "./TextWithGoldHeader.module.css"
import GoldHeaderText from "../GoldHeaderText/GoldHeaderText"
import FadeInSection from "../../../FadeInSection/FadeInSection"

const TextWithGoldHeader = props => {
  const { contentsArray } = props
  return (
    <div className={classes.container}>
      {contentsArray.map((item, index) => (
      <div key={index} className={classes.contentsContainer}>
        <FadeInSection timeout={item.timeout}>
        <GoldHeaderText displayText={item.headerText} />
        </FadeInSection>
        <FadeInSection timeout={item.timeout + 100}>
        <p className={classes.displayText}>{item.displayText}</p>
        </FadeInSection>
      </div>
      ))}
    </div>
  )
}

export default TextWithGoldHeader
