import React from "react"
import classes from "./About.module.css"
import ContentContainer from "../../ContentContainer/ContentContainer"
import GoldHeaderText from "../components/GoldHeaderText/GoldHeaderText"
import FadeInSection from "../../FadeInSection/FadeInSection"

const About = () => {
  const displayText = ["We Set The Bar For Luxury.", "You Get To Live It."]
  return (
    <section id="about" className={classes.aboutRoot}>
      <ContentContainer>
        <FadeInSection timeout={250}>
          <GoldHeaderText displayText={displayText} />
        </FadeInSection>

        <FadeInSection timeout={500}>
          <p className={classes.aboutParagraph}>
            Innovation, authenticity and creativity is who we are. Using these
            key pillars, coupled with an esteemed level of expertise, we
            translate your individuality into an extraordinary property all your
            own.
          </p>
        </FadeInSection>
      </ContentContainer>
    </section>
  )
}

export default About
