import React from "react"
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"
import classes from "./HeroSection.module.css"

const images = [
  {
    original: require("../../../images/1.jpg"),
    thumbnail: require("../../../images/1.jpg"),
  },
  {
    original: require("../../../images/2.jpg"),
    thumbnail: require("../../../images/2.jpg"),
  },
  {
    original: require("../../../images/3.jpg"),
    thumbnail: require("../../../images/3.jpg"),
  },
  {
    original: require("../../../images/4.jpg"),
    thumbnail: require("../../../images/4.jpg"),
  },
  {
    original: require("../../../images/5.jpg"),
    thumbnail: require("../../../images/5.jpg"),
  },
  {
    original: require("../../../images/6.jpg"),
    thumbnail: require("../../../images/6.jpg"),
  },
  {
    original: require("../../../images/7.jpg"),
    thumbnail: require("../../../images/7.jpg"),
  },
  {
    original: require("../../../images/8.jpg"),
    thumbnail: require("../../../images/8.jpg"),
  },
  {
    original: require("../../../images/9.jpg"),
    thumbnail: require("../../../images/9.jpg"),
  },
  {
    original: require("../../../images/10.jpg"),
    thumbnail: require("../../../images/10.jpg"),
  },
  {
    original: require("../../../images/11.jpg"),
    thumbnail: require("../../../images/11.jpg"),
  },
  {
    original: require("../../../images/12.jpg"),
    thumbnail: require("../../../images/12.jpg"),
  },
  {
    original: require("../../../images/13.jpg"),
    thumbnail: require("../../../images/13.jpg"),
  },
  {
    original: require("../../../images/14.jpg"),
    thumbnail: require("../../../images/14.jpg"),
  },
{
   original: require("../../../images/15.jpg"),
   thumbnail: require("../../../images/15.jpg"),
 },
  {
    original: require("../../../images/16.jpg"),
    thumbnail: require("../../../images/16.jpg"),
  },
  {
    original: require("../../../images/17.jpg"),
    thumbnail: require("../../../images/17.jpg"),
  },
  {
    original: require("../../../images/18.jpg"),
    thumbnail: require("../../../images/18.jpg"),
  },
  {
    original: require("../../../images/19.jpg"),
    thumbnail: require("../../../images/19.jpg"),
  },
  {
    original: require("../../../images/20.jpg"),
    thumbnail: require("../../../images/20.jpg"),
  },
  {
    original: require("../../../images/21.jpg"),
    thumbnail: require("../../../images/21.jpg"),
  },
  {
    original: require("../../../images/22.jpg"),
    thumbnail: require("../../../images/22.jpg"),
  },
  {
    original: require("../../../images/23.jpg"),
    thumbnail: require("../../../images/23.jpg"),
  },
  {
    original: require("../../../images/24.jpg"),
    thumbnail: require("../../../images/24.jpg"),
  },
  {
    original: require("../../../images/25.jpg"),
    thumbnail: require("../../../images/25.jpg"),
  },
  {
    original: require("../../../images/26.jpg"),
    thumbnail: require("../../../images/26.jpg"),
  },
  {
    original: require("../../../images/27.jpg"),
    thumbnail: require("../../../images/27.jpg"),
  },
  {
    original: require("../../../images/28.jpg"),
    thumbnail: require("../../../images/28.jpg"),
  },
]

const HeroSection = () => {
  return (
    <section id="top" className={classes.heroRoot}>
      <ImageGallery
        items={images}
        showFullscreenButton={false}
        showPlayButton={false}
        showBullets={false}
        showNav={false}
        autoPlay
        slideDuration={1000}
      />
    </section>
  )
}

export default HeroSection
