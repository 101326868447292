import React from "react"
import classes from "./BlackHeaderText.module.css"
import FadeInSection from "../../../FadeInSection/FadeInSection"

const BlackHeaderText = props => {
  const { displayText } = props
  return (
    <div>
      <FadeInSection timeout={250}>
        <h2 className={classes.displayText}>{displayText}</h2>
      </FadeInSection>

      <FadeInSection timeout={350}>
        <div className={classes.blackLine} />
      </FadeInSection>
    </div>
  )
}

export default BlackHeaderText
