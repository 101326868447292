import React from "react"
import classes from "./Contact.module.css"
import ContentContainer from "../../ContentContainer/ContentContainer"
import GoldHeaderText from "../components/GoldHeaderText/GoldHeaderText"
import Form from "../components/Form/Form"

const Contact = () => {
  const displayText = ["Contact"]
  return (
    <section id="contact" className={classes.contactRoot}>
      <ContentContainer>
        <GoldHeaderText displayText={displayText} />
        <Form />
      </ContentContainer>
    </section>
  )
}

export default Contact
