import React from "react"
import classes from "./Values.module.css"
import ContentContainer from "../../ContentContainer/ContentContainer"
import BlackHeaderText from "../components/BlackHeaderText/BlackHeaderText"
import TextWithGoldHeader from "../components/TextWithGoldHeader/TextWithGoldHeader"
import FadeInSection from "../../FadeInSection/FadeInSection"

const Values = () => {
  const displayText = "Values"
  const valuesArray = [
    {
      headerText: ["Service"],
      displayText:
        "We take a concierge approach that anticipates the needs of each client. " +
        "With transparency, knowledge, integrity and commitment we deliver a " +
        "truly unique and memorable experience. ",
      timeout: 500,
    },
    {
      headerText: ["Quality"],
      displayText:
        "Setting the bar and superseding convention. Our attention to detail is " +
        "unapologetic. Settling for anything else is not FRANK.",
      timeout: 700,
    },
    {
      headerText: ["Creativity"],
      displayText:
        "We are curious thinkers and real doers. By pushing the boundaries " +
        "and embracing cutting edge technologies we seek the undiscovered. " +
        "Proving the impossible is possible.",
      timeout: 800,
    },
  ]
  return (
    <section id="values" className={classes.valuesRoot}>
     <ContentContainer>
       <BlackHeaderText displayText={displayText} />
       <TextWithGoldHeader contentsArray={valuesArray} />
     </ContentContainer>
     <FadeInSection timeout={700}>
       <div className={classes.imgBack}>
       </div>
     </FadeInSection>
    </section>
  )
}

export default Values
