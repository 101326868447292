import React from "react"
import classes from "./GoldHeaderText.module.css"

const GoldHeaderText = props => {
  const { displayText } = props
  return displayText.map((item, index) => (
    <h1 key={index} className={classes.goldHeader}>{item.valueOf()}</h1>
  ))
}

export default GoldHeaderText
