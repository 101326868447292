import React, { useEffect, useState } from "react"
import SEO from "../components/seo"
import HeroSection from "../components/Sections/HeroSection/HeroSection"
import About from "../components/Sections/About/About"
import Values from "../components/Sections/Values/Values"
import Contact from "../components/Sections/Contact/Contact"

const IndexPage = () => {
  // const [isLoading, setIsLoading] = useState(true)
  //
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false)
  //   }, 1000)
  // }, [isLoading])

  return (
    <React.Fragment>
      <SEO title="Home" />
      <HeroSection />
      <About />
      <Values />
      <Contact />
    </React.Fragment>
  )
}

export default IndexPage
